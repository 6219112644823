import { Alert, Button, Form, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { get, download } from "../api/base";
import { LoadingOutlined, DownloadOutlined } from '@ant-design/icons';
const package_json = require('../..//package.json');

export const Home = () => {
  const [cities, setCities] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    get('/cities',
      (successResponse) => {
        setCities(successResponse.cities)
      }
    )
  }, [])

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const onSubmit = async (form) => {
    setLoading(true)
    await download(`/download/${form.code}`, cities.find(e => e.value === form.code).label.split(' ')[0])
    setLoading(false)
  }

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} spinning={loading}>
    <Alert message={`Version : ${package_json.version}`} style={{ margin: '10px' }} type="info" showIcon />
      <Form onFinish={onSubmit}>
        <Form.Item name='code'>
          <Select
            showSearch
            placeholder="Sélectionnez une ville"
            optionFilterProp="children"
            filterOption={filterOption}
            options={cities}/>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" icon={<DownloadOutlined />} size='large'>
            Télécharger
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  )
}

