import { notification } from "antd"

const SERVER_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BACK_URL : 'http://127.0.0.1:5000/api'
const request = async (url, options) => {
  const res = await fetch(SERVER_URL + url, options)
  const data = await res.json()

  return [res.status, data]
}

const newHeader = (withToken) => {
  const headers = new Headers()
  return headers
}

const getRequestOptions = (method, params, withToken) => {
  const headers = newHeader(withToken)
  headers.append('Content-Type', 'application/json;charset=UTF-8;')

  return {
    method: method,
    headers: headers,
    body: JSON.stringify(params)
  }
}

const afterRequest = (status, res, onSuccess, onError) => {
  if (status === 200) {
    onSuccess(JSON.parse(res))
  } else {
    onError(res)
  }
}

const handleErrorDefault = (res) => {
  if (res.msg === 'Token has expired') {
    localStorage.clear()
  }
  console.log(res.msg)
}

export const get = async (url, onSuccess, onError = handleErrorDefault, withToken = true) => {
  const headers = newHeader(withToken)

  const [status, data] = await request(url, { headers: headers })

  afterRequest(status, data, onSuccess, onError)
}

export const post = async (url, params, onSuccess, onError = handleErrorDefault, withToken = true) => {
  const requestOptions = getRequestOptions('POST', params, withToken)

  const [status, data] = await request(url, requestOptions)

  afterRequest(status, data, onSuccess, onError)
}

export const download = async (url, fileName) => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json;charset=UTF-8;')
  headers.append('Content-Type', 'text.html')
  headers.append('Content-Disposition', 'attachment')
  const requestOptions = {
    method: 'GET',
    headers: headers,
  }
  const response = await fetch(SERVER_URL + url, requestOptions)
  if (response.status !== 200) {
    const error = await response.json()
    notification.error({ ...error, placement: 'bottom' })

    return false
  }
  const blob = await response.blob()
  const contentType = response.headers.get("Content-Type");
  let a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  const newBlob = blob.slice(0, blob.size, contentType);
  const objectURL = URL.createObjectURL(newBlob);
  a.href = objectURL;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(objectURL);
}

export const patch = async (url, params, onSuccess, onError = handleErrorDefault, withToken = true) => {
  const requestOptions = getRequestOptions('PATCH', params, withToken)

  const [status, data] = await request(url, requestOptions)

  afterRequest(status, data, onSuccess, onError)
}

export const put = async (url, params, onSuccess, onError = handleErrorDefault, withToken = true) => {
  const requestOptions = getRequestOptions('PUT', params, withToken)

  const [status, data] = await request(url, requestOptions)

  afterRequest(status, data, onSuccess, onError)
}

export const deleteRequest = async (url, params, onSuccess, onError = handleErrorDefault, withToken = true) => {
  const requestOptions = getRequestOptions('DELETE', params, withToken)

  const [status, data] = await request(url, requestOptions)

  afterRequest(status, data, onSuccess, onError)
}